/* eslint-disable indent */
import jwtdecode from "jwt-decode";
import { isEmpty, get } from "lodash";
import store from "./store";
import userMe from "./services/user-me";
import initOrg from "./services/init-organization-info";
import USER_TYPE_CONSTANTS from "@/constants/user-type-constants";
import notification from "@/utils/notifications";
// import authService from "@/auth/auth.service";
import { getInstance } from "@/auth";

export default function(router) {
  router.beforeEach(async (to, from, next) => {
    const authService = await getInstance();
    if (!authService) {
      // Handle the case where authService is undefined
      console.error("Auth service is not available.");
      return;
    }
    const guardAction = async () => {
      if (!authService.isAuthenticated && to.name !== "callback") {
        await store.dispatch("clearLocalStore");
        await authService.loginWithRedirect();
        return;
      }

      // GET BIOTE_USER_DATA
      let userIdentity = store.getters["publicAPIModule/getUserIdentity"];
      const accessToken = await authService.getAccessTokenSilently();
      if (userIdentity === null) {
        localStorage.removeItem("userPrimaryState");
        const idProofingResponse = await store.dispatch("publicAPIModule/identifyUserIdProofing", accessToken);
        if (idProofingResponse === null) {
          return await store.dispatch("publicAPIModule/logoutUser");
        }
        const primaryState = get(idProofingResponse, "primaryState", "");
        localStorage.setItem("userPrimaryState", primaryState);
      }
      const { loginExpTime, loginAccessToken } = getLocalStorageValues();
      const validAuthorization = loginExpTime && loginAccessToken;
      const offCanvasMenuStatus = store.getters.getOffCanvasMenuStatus;

      if (offCanvasMenuStatus) {
        store.commit("toggleOffCanvasMenu", { toggle: false });
      }

      if ((to.meta.onboarding || to.name === "callback" || to.name === "IDMeCallback") && !validAuthorization) {
        next();
        return;
      }

      const isTokenExpired = checkTokenExpiration(loginExpTime);

      if (!validAuthorization || isTokenExpired) {
        handleExpiredToken(to, next);
        return;
      }

      await handleValidToken(to, next);
    };
    if (!authService || !authService.isLoading) {
      return guardAction();
    }
    authService.$watch("isLoading", isLoading => {
      if (isLoading === false) {
        return guardAction();
      }
    });
  });
}

function getLocalStorageValues() {
  const exp = localStorage.getItem("exp");
  const accessToken = localStorage.getItem("access_token");
  return {
    loginExpTime: !["", null, undefined].includes(exp) ? exp : null,
    loginAccessToken: !["", null, undefined].includes(accessToken) ? accessToken : null,
    // loginIdToken: localStorage.getItem("id_token"),
    selectedVendor: localStorage.getItem("selected_vendor"),
    hydraState: localStorage.getItem("hydraState"),
    orgId: localStorage.getItem("orgId"),
  };
}

function checkTokenExpiration(loginExpTime) {
  const tokenExpiration = Number(loginExpTime);
  return tokenExpiration < Math.floor(Date.now() / 1000);
}

async function handleExpiredToken(to, next) {
  localStorage.callbackRoute = to.path;
  await store.dispatch("clearLocalStore");
  await store.dispatch("publicAPIModule/logoutUser");
}

async function handleValidToken(to, next) {
  let { loginExpTime, loginAccessToken, selectedVendor } = getLocalStorageValues();
  const decodedToken = jwtdecode(loginAccessToken);
  const { userType } = decodedToken.sim.user;
  const isSimpatraSuperAdmin = get(decodedToken.sim, "simpatraAdmin", false);
  const isVendorView = !["", null, undefined].includes(selectedVendor);
  if (!loginAccessToken || !loginExpTime) {
    localStorage.setItem("access_token", loginAccessToken);
    localStorage.setItem("exp", loginExpTime);
  }

  const hasVendorPageAccess = userType === USER_TYPE_CONSTANTS.vendorUser || isSimpatraSuperAdmin;
  if (!hasVendorPageAccess) {
    selectedVendor = null;
    localStorage.removeItem("selected_vendor");
  }

  if (isEmpty(store.getters["usersModule/getMyInfo"])) {
    await store.dispatch("usersModule/initializeCurrentLoggedInInfo", {
      exp: loginExpTime,
      access_token: loginAccessToken,
      decodedToken,
    });
    // Partially added setUserInfo here, need more time refactor the old code
    await store.dispatch("setUserInfo", decodedToken);
  }

  // FETCHING THE ORGANIZATION USER DATA
  if (isEmpty(store.getters["usersModule/getMyUserInfo"])) {
    await store.dispatch("usersModule/fetchMyUserInfo");
  }

  // Initialization of orgId or vendorId
  await reInitLocalStorageDetails({
    isVendorView,
    userType,
    decodedToken,
    isSimpatraSuperAdmin,
  });

  let currentLocation = null;
  if (!isVendorView) {
    const result = await initOrg.fetchOrgInitialDetails({
      userType,
      isSimpatraSuperAdmin,
    });
    if (result) {
      currentLocation = result.selectedLocation || "";
    }
  }

  const isSimpatraAdminPage = to.matched.some(record => record.meta.simpatraAdmin || record.meta.simpatraAdminAccessRequired);
  // Validate when user accessing super admin page
  if (isSimpatraAdminPage) {
    if (isSimpatraSuperAdmin) {
      next();
    } else {
      next("/");
    }
  } else if (isVendorView && hasVendorPageAccess && to.name && to.meta.checkVendorAccessibility) {
    next();
  } else if (isVendorView && hasVendorPageAccess) {
    next({
      name: "VendorOrderList",
      params: { vendorId: selectedVendor },
    });
  } else {
    const navigatePagesToHome = ["callback", "PracticeInformation", undefined, "Provider"];
    if (to && (navigatePagesToHome.includes(to.name) || to.meta.checkVendorAccessibility)) {
      next(`/dashboard/${currentLocation}/patient-chart/`);
    } else {
      next();
    }
  }
}

const reInitLocalStorageDetails = async ({ isVendorView, userType, decodedToken, isSimpatraSuperAdmin }) => {
  const { orgId, selectedVendor } = getLocalStorageValues();

  if (userType === USER_TYPE_CONSTANTS.organizationUser && !isVendorView) {
    // This is the org id of the current login, this is for security purpose
    // We always used decoded token to avoid using localStorage when they tamper the orgId
    const currentLogginOrgId = get(decodedToken.sim.details, "id", null);
    if (isSimpatraSuperAdmin && (currentLogginOrgId !== orgId && !isEmpty(orgId))) {
      localStorage.setItem("orgId", orgId);
    } else {
      localStorage.setItem("orgId", currentLogginOrgId);
    }
  } else if (userType === USER_TYPE_CONSTANTS.vendorUser) {
    const vendorId = get(decodedToken.sim.details, "id", "");
    if (isEmpty(selectedVendor)) {
      localStorage.setItem("selected_vendor", vendorId);
    }
  }
};

// Being used in patient-chart route
export function hasPatientChartAccess() {
  return userMe.getMyRoles().isPatientAdmin;
}

// router guard when user is navigating to onboarding page
export async function allowToOnboard(to, from, next) {
  const practiceUserFormData = store.getters["onboardingModule/getPracticeUserFormData"];
  const authService = await getInstance();
  if (authService.isAuthenticated && !isEmpty(practiceUserFormData)) {
    next();
  } else if (authService.isAuthenticated && isEmpty(practiceUserFormData)) {
    const accessToken = await authService.getAccessTokenSilently();
    const response = await store.dispatch("publicAPIModule/validateAuth0Token", { accessToken });
    if (response === null) await store.dispatch("publicAPIModule/logoutUser");
    store.commit("onboardingModule/appendDataToPracticeUserFormData", response.data);
    next();
  } else {
    window.location.href = "/";
  }
}

