import BaseProxy from "../../base-proxy";

// UTILS
import notification from "@/utils/notifications";
import { get } from "lodash";

// CONSTANTS
import NOTIFICATION_CONSTANTS from "@/constants/notification-type-constants";

export default class GetSimpatraServiceProxy {
  async execute(query = "", queryParams = {}, additionalConfig, axiosConfig) {
    const baseProxy = new BaseProxy();
    const backendUrl = await baseProxy.getSimpatraServiceUrl();
    const endpointUrl = `${backendUrl}/${query}`;
    try {
      if (get(queryParams, "q", "")) {
        let trimmedQuery = queryParams.q.trim();
        if (trimmedQuery && /[^a-zA-Z0-9+ ]/.test(trimmedQuery)) {
          queryParams.q = encodeURIComponent(trimmedQuery);
        } else {
          queryParams.q = trimmedQuery;
        }
      }
      return await baseProxy.getSimpatraServiceData(endpointUrl, queryParams, axiosConfig);
    } catch (error) {
      const errorMsgHeader =
        error.response && error.response.data ? error.response.data.error || "ERROR!" : error.response.error ? error.response.error : "Event failed due to error!";
      if (additionalConfig && additionalConfig.notificationParams) {
        const { notificationParams } = additionalConfig;
        const errorMsg = notificationParams && notificationParams.errorNotificationMsg ? notificationParams.errorNotificationMsg : error.response.data.message || null;
        if (!notificationParams.hideErrorNotif) {
          notification(NOTIFICATION_CONSTANTS.ERROR, errorMsgHeader, errorMsg);
        }
        if (notificationParams && notificationParams.returnError) {
          return error && error.response;
        }
      } else {
        notification(NOTIFICATION_CONSTANTS.ERROR, errorMsgHeader, error.response.data.message || "");
      }

      return error;
    }
  }
}

