import { get } from "lodash";
import BaseProxy from "../../base-proxy";

// UTILS
import notification from "@/utils/notifications";

// CONSTANTS
import NOTIFICATION_CONSTANTS from "@/constants/notification-type-constants";

export default class PatchSimpatraServiceProxy {
  async execute(query = "", payload = {}, notificationParams) {
    const baseProxy = new BaseProxy();
    const backendUrl = await baseProxy.getSimpatraServiceUrl();
    const endpointUrl = `${backendUrl}/${query}`;
    try {
      const response = await baseProxy.patchSimpatraServiceData(endpointUrl, payload);
      if (notificationParams && notificationParams.displaySuccessMessage) {
        notification(NOTIFICATION_CONSTANTS.SUCCESS, "Successfully saved!", get(notificationParams, "successNotificationMsg", ""));
      }
      return response;
    } catch (error) {
      notification(
        NOTIFICATION_CONSTANTS.WARNING,
        "Failed to save due to error!",
        notificationParams && notificationParams.errorNotificationMsg
          ? notificationParams.errorNotificationMsg
          : error.response.data.message
      );
      if (notificationParams && notificationParams.returnError) {
        return error && error.response;
      }
    }
  }
}
