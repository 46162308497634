import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import orgModuleActions from "./orgModule/actions";
import vendorModuleActions from "./vendorModule/actions";
import locationModuleActions from "./locationModule/actions";

const fetchPatientAccountDetailsById = async (_, patientId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/patient/${patientId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  fetchPatientAccountDetailsById,
  ...orgModuleActions,
  ...vendorModuleActions,
  ...locationModuleActions,
};
