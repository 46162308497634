import { get } from "lodash";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";
import DeleteSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/delete";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import PatchSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/patch";
import notification from "@/utils/notifications";
import ValidationHelper from "@/utils/validation-helper.js";

const fetchOrgDetails = async ({ commit }, { orgId, returnResponseOnly = false }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/org/${orgId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    if (!returnResponseOnly) {
      commit("setOrgDetails", data);

      // ALL LOCATIONS
      if (data.activeLocations && data.activeLocations.length > 0) {
        //   const selectedLocation = localStorage.getItem("currentLocation");
        //   ("selectedLocation", "selectedLocation")
        //   if (selectedLocation === null || selectedLocation === undefined) {
        //     localStorage.setItem("currentLocation", data.activeLocations[0].id);
        //   }
        commit("setOrgLocations", data.activeLocations);
      }
      // DOSING DETAILS
      if (data.dosing) {
        commit("setOrgDosingDetails", data.dosing);
      }
    }
    return data;
  }
};

const fetchOrgDosingUsers = async ({ commit }, { orgId, returnResponseOnly = false }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/org/${orgId}/dosing-users`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    if (!returnResponseOnly) {
      commit("setOrgDosingUsers", data);
    }

    return data;
  }
};

const fetchOrgUsers = async ({ commit }, { orgId, currentPage = 0, pageSize = 10, queryParams = {}, paginate = true, isLookup = false }) => {
  const proxy = new GetSimpatraServiceProxy();
  let query = `accounts/v1/org/${orgId}/users/`;

  if (paginate) {
    let params = `pagenumber/${currentPage}/pagesize/${pageSize}`;
    query += params;
  }

  const { data } = await proxy.execute(query, queryParams);
  if (!isLookup) commit("setOrgUsers", data);

  return data;
};

// Query Params Filters (isDosingUser, isActive)
const fetchOrgUserLookup = async ({ commit }, { orgId, queryParams = {} }) => {
  const proxy = new GetSimpatraServiceProxy();
  let query = `accounts/v1/org/${orgId}/organization-users`;

  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    commit("setOrgUserLookup", data);
  } else {
    commit("setOrgUserLookup", []);
  }
};

const addOrgUser = async ({ commit }, payload) => {
  const proxy = new PostSimpatraServiceProxy();
  const { orgId } = payload;
  const body = {
    firstName: payload.firstName,
    lastName: payload.lastName,
    email: payload.email,
  };
  const query = `accounts/v1/org/${orgId}/user`;
  const response = await proxy.execute(query, body, {
    displaySuccessMessage: false,
    successNotificationMsg: "",
  });

  if (response) {
    const { data } = response;
    commit("updateOrgUser", {
      type: "ADD",
      data,
    });
    return data;
  }
};

const deleteOrgUser = async ({ commit }, { orgId, userId }) => {
  const proxy = new DeleteSimpatraServiceProxy();
  const query = `accounts/v1/org/${orgId}/user/${userId}?delete=1`;
  const { data } = await proxy.execute(query, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully deleted user!",
  });
  if (data) {
    commit("updateOrgUser", { type: "DELETE", userId });
  }
};

const updateOrgUserStatus = async (_, { orgId, userId, isActive }) => {
  const proxy = new PutSimpatraServiceProxy();
  let query = `accounts/v1/org/${orgId}/usr/${userId}/${isActive}`;
  const successNotificationMsg = `User successfully ${isActive ? "activated" : "deactivated"}!`;
  await proxy.execute(query, null, {
    displaySuccessMessage: true,
    successNotificationMsg,
  });
};

const updateOrgUserInfo = async ({ commit }, payload) => {
  const { userId, orgId } = payload;
  const body = {
    firstName: payload.firstName,
    lastName: payload.lastName,
    phone: payload.phone,
    email: payload.email,
  };

  const proxy = new PutSimpatraServiceProxy();
  let query = `accounts/v1/org/${orgId}/user/${userId}`;
  const successNotificationMsg = `User info successfully updated!`;
  const response = await proxy.execute(query, body, {
    displaySuccessMessage: true,
    successNotificationMsg,
  });
  if (response) {
    commit("updateOrgUser", payload);
    return response;
  }
};

const updateLocation = async (_, { orgId, userId, locationIdArray }) => {
  const proxy = new PutSimpatraServiceProxy();
  let query = `accounts/v1/org/${orgId}/user/${userId}/access`;
  const successNotificationMsg = `User location successfully updated!`;
  const response = await proxy.execute(query, locationIdArray, {
    displaySuccessMessage: true,
    successNotificationMsg,
  });
  if (response) {
    return get(response, "data", null);
  }
};

const fetchOrgList = async ({ commit }, { page = 0, pageSize = 10, params, returnResponseOnly = false }) => {
  const proxy = new GetSimpatraServiceProxy();
  let query = `accounts/v1/org/list/pagenumber/${page}/pagesize/${pageSize}`;
  if (params) {
    const validatorUtil = new ValidationHelper();
    let validatedValue = params.name;
    if (validatedValue) {
      validatedValue = validatorUtil.validateSearchParam(params.name);
    }
    query = `${query}?name=${validatedValue}`;
  }
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    if (returnResponseOnly) {
      return data;
    } else {
      commit("setOrgList", data);
    }
  }
};

const removeOrgAdminPermission = async (_, { orgId, userId, type }) => {
  const proxy = new DeleteSimpatraServiceProxy();
  const query = `accounts/v1/org/${orgId}/user/${userId}/admin`;
  const actionTypeLabel = type === "create" ? "added" : "updated";
  const response = await proxy.execute(query, {
    displaySuccessMessage: true,
    successNotificationMsg: `Successfully ${actionTypeLabel} permissions`,
  });

  if (response) {
    return get(response, "data", null);
  }
};

const grantOrgAdminPermission = async (_, { orgId, userId, type }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `accounts/v1/org/${orgId}/user/${userId}/admin`;
  const actionTypeLabel = type === "create" ? "added" : "updated";
  const response = await proxy.execute(query, null, {
    displaySuccessMessage: true,
    successNotificationMsg: `Successfully ${actionTypeLabel} permissions`,
  });

  if (response) {
    return get(response, "data", null);
  }
};

const removeDosingAccess = async ({ commit }, { orgId, userId }) => {
  const proxy = new DeleteSimpatraServiceProxy();
  const query = `accounts/v1/org/${orgId}/dosing/user/${userId}`;
  const response = await proxy.execute(query, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully removed access!",
  });

  if (response.data) {
    commit("updateOrgDosingUsers", { type: "delete", userId });
  }
};

const updateLocationPermissions = async (_, { orgId, userId, locationPermissions }) => {
  const proxy = new PutSimpatraServiceProxy();
  const query = `accounts/v1/org/${orgId}/user/${userId}/permission/location`;
  const successNotificationMsg = "Updated org locations successfully!";
  const response = await proxy.execute(query, locationPermissions, {
    displaySuccessMessage: true,
    successNotificationMsg,
  });
  if (response) {
    return get(response, "data", null);
  }
};

const updateOrgPermissions = async (_, { orgId, userId, orgPermissions }) => {
  const proxy = new PutSimpatraServiceProxy();
  const query = `accounts/v1/org/${orgId}/user/${userId}/permission`;
  const response = await proxy.execute(query, orgPermissions, {
    displaySuccessMessage: true,
    successNotificationMsg: "Updated org permissions successfully!",
  });
  if (response) {
    return get(response, "data", null);
  }
};

const updateSuperAdminPermissions = async (_, { orgId, userId, supAdminPermissions }) => {
  const proxy = new PutSimpatraServiceProxy();
  const query = `accounts/v1/org/${orgId}/user/${userId}/permission/superadmin`;
  const response = await proxy.execute(query, supAdminPermissions, {
    displaySuccessMessage: true,
    successNotificationMsg: "Updated org permissions successfully!",
  });
  if (response) {
    return get(response, "data", null);
  }
};

const addDosingAccess = async ({ commit }, { orgId, userId }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `accounts/v1/org/${orgId}/dosing/user/${userId}`;
  const notificationParams = {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully added access!",
  };
  const response = await proxy.execute(query, null, notificationParams);
  if (response) {
    const { data } = response;
    commit("updateOrgDosingUsers", { type: "add", data });
  }
};

const updateOrgDetails = async (_, { orgId, payload, notificationMsg }) => {
  const proxy = new PutSimpatraServiceProxy();
  const query = `accounts/v1/org/${orgId}`;

  await proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg: notificationMsg ? notificationMsg : "Updated org details successfully!",
  });
};

const updateOrgDosingDetails = async (_, { orgId, dosing }) => {
  const proxy = new PutSimpatraServiceProxy();
  const body = {
    enabled: dosing.enabled,
    freeDoses: dosing.freeDoses,
    maxDoses: dosing.maxDoses,
    numDosingUsers: dosing.numDosingUsers,
    pricePerDose: dosing.pricePerDose,
    billingGracePeriod: dosing.billingGracePeriod,
  };
  const query = `accounts/v1/admin/organization/${orgId}/dosing`;
  await proxy.execute(query, body, {
    displaySuccessMessage: true,
    successNotificationMsg: "Updated dosing details successfully!",
  });
};

const fetchUserAccountSetupStatus = async (_, userId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/org/user/check/${userId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

const updateOrgUserPassword = async (_, { userId, payload }) => {
  const proxy = new PutSimpatraServiceProxy();
  const query = `accounts/v1/org/user/check/${userId}/update`;
  await proxy.execute(query, payload, "Successfully sets user password!");
};

const fetchDeaLicenses = async ({ commit }, { locationId, orgId, queryParams = {}, initState }) => {
  const proxy = new GetSimpatraServiceProxy();
  let query = `accounts/v1/org/${orgId}/location/${locationId}/dea`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    if (initState) {
      commit("setDeaLicenses", response.data);
    }
    return response.data;
  }
};

const renewDeaLicense = async ({ commit }, { locationId, orgId, body }) => {
  const proxy = new PostSimpatraServiceProxy();
  let query = `accounts/v1/org/${orgId}/location/${locationId}/dea?renew=true`;
  const response = await proxy.execute(query, body, {
      displaySuccessMessage: true,
      successNotificationMsg: "Successfully renewed DEA License!",
  });
  if (response) {
    return response.data;
  }
};

const fetchNPIbyId = async (_, npiId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `/accounts/v1/npi/${npiId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchNpi = async ({ commit }, { orgId, locationId, queryParams = {}, initState }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/org/${orgId}/location/${locationId}/npi`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    if (initState) {
      commit("setNPI", response.data);
    }
    return data;
  }
};

const renewNpiLicense = async ({ commit }, { locationId, orgId, body }) => {
  const proxy = new PostSimpatraServiceProxy();
  let query = `accounts/v1/org/${orgId}/location/${locationId}/npi?renew=true`;
  const response = await proxy.execute(query, body, {
      displaySuccessMessage: true,
      successNotificationMsg: "Successfully renewed NPI License!",
  });
  if (response) {
    return response.data;
  }
};

const fetchMedicalLicenses = async ({ commit }, { orgId, locationId, queryParams = {}, initState }) => {
  const proxy = new GetSimpatraServiceProxy();
  let query = `accounts/v1/org/${orgId}/location/${locationId}/medical`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    if (initState) {
      commit("setMedicalLicenses", response.data);
    }
    return response.data;
  }
};

const renewMedicalLicense = async ({ commit }, { locationId, orgId, body }) => {
  const proxy = new PostSimpatraServiceProxy();
  let query = `accounts/v1/org/${orgId}/location/${locationId}/medical?renew=true`;
  const response = await proxy.execute(query, body, {
      displaySuccessMessage: true,
      successNotificationMsg: "Successfully renewed Medical License!",
  });
  if (response) {
    return response.data;
  }
};

const fetchOrgLicense = async ({ commit }, { orgId, locationId, queryParams = {}, licenseType }) => {
  const proxy = new GetSimpatraServiceProxy();
  let query = `accounts/v1/org/${orgId}/location/${locationId}/${licenseType}`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    return get(response, "data", []);
  }
};

export default {
  fetchOrgDetails,
  fetchOrgDosingUsers,
  fetchOrgUsers,
  fetchOrgList,
  updateOrgDosingDetails,
  updateOrgDetails,
  updateOrgUserStatus,
  removeDosingAccess,
  addDosingAccess,
  deleteOrgUser,
  updateOrgUserInfo,
  updateLocation,
  removeOrgAdminPermission,
  grantOrgAdminPermission,
  updateLocationPermissions,
  updateOrgPermissions,
  updateSuperAdminPermissions,
  addOrgUser,
  fetchUserAccountSetupStatus,
  updateOrgUserPassword,
  fetchDeaLicenses,
  renewDeaLicense,
  fetchNPIbyId,
  fetchNpi,
  renewNpiLicense,
  fetchMedicalLicenses,
  renewMedicalLicense,
  fetchOrgUserLookup,
  fetchOrgLicense,
};

