import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import PatchSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/patch";

const executeGetQuery = async (query, queryParams = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  const response = await proxy.execute(query, queryParams);
  return response ? response.data : null;
};

const createUpdatePatient = async (_, { type = "add", payload, locationId, patientId }) => {
  const post_proxy = new PostSimpatraServiceProxy();
  const put_proxy = new PutSimpatraServiceProxy();
  const isUpdate = type === "update";
  let query = `jpatient/v1/location/${locationId}/patient`;
  if (isUpdate) {
    query += `/${patientId}`;
  }
  let response;

  if (isUpdate) {
    const successNotificationMsg = `Successfully updated patient!`;
    response = await put_proxy.execute(query, payload, {
      displaySuccessMessage: true,
      successNotificationMsg,
    });
  } else {
    response = await post_proxy.execute(query, payload, {
      displaySuccessMessage: true,
      successNotificationMsg: "Successfully created patient."
    });
  }

  if (response) {
    const { data } = response;
    return data;
  }
};

const createUpdatePatientUserInfo = async (_, { type = "add", payload, patientId }) => {
  const post_proxy = new PostSimpatraServiceProxy();
  const put_proxy = new PutSimpatraServiceProxy();
  const isUpdate = type === "update";
  let query = `j-idp/v1/patient/${patientId}`;
  let response;

  if (isUpdate) {
    const successNotificationMsg = `Successfully updated patient!`;
    response = await put_proxy.execute(query, payload, {
      displaySuccessMessage: true,
      successNotificationMsg,
    });
  } else {
    query += "/activate";
    response = await post_proxy.execute(query, payload, {
      displaySuccessMessage: true,
      successNotificationMsg: `Successfully activated Patient.`
    });
  }

  if (response) {
    const { data } = response;
    return data;
  }
};

const createPatientMergedInfo = async (_, { payload, locationId, isOriginFromMedcenter = false }) => {
  const proxy = new PostSimpatraServiceProxy();
  let query = `jpatient/v1/location/${locationId}/patient/activate?isOriginFromMedcenter=${isOriginFromMedcenter}`;

  const response = await proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg: `Successfully created patient.`
  });

  if (response) {
    const { data } = response;
    return data;
  }
};

const updatePatientSendInvoiceStatus = async (_, { locationId, payload, patientId }) => {
  const proxy = new PatchSimpatraServiceProxy();
  let query = `jpatient/v1/location/${locationId}/patient/${patientId}`;
  let response = await proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg: `Successfully Update Patient Send Invoice Status.`
  });

  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchPatientList = async ({ commit }, { type, locationId, searchQuery = "", isLookups = false }) => {
  const proxy = new GetSimpatraServiceProxy();
  const queryParams = {
    q: searchQuery
  };
  if (type) {
    queryParams["type"] = type;
  }
  const query = `jpatient/v1/location/${locationId}/patient/search`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    if (isLookups) {
      return data;
    } else {
      commit("setPatientList", data);
    }
  }
};

const fetchPatientListCount = async ({ commit }, locationId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `jpatient/v1/location/${locationId}/patient/count`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setTotalPatient", {
      type: "init",
      value: data
    });
    return data;
  }
};

const fetchPatientAllergyHistory = async (_, { patientId, pageNumber, pageSize, queryParams = {} }) => {
  let query = `jpatient/v1/patient/${patientId}/allergy-history`;

  // If pageNumber and pageSize are provided, append them as query parameters
  // pageNumber 0 means first page
  if ((pageNumber || pageNumber === 0) && (pageSize || pageSize === 0)) {
    queryParams.pageNumber = pageNumber;
    queryParams.pageSize = pageSize;
  }

  return await executeGetQuery(query, queryParams);
};


const addPatientAllergyHistory = async (_, { payload, patientId }) => {
  const post_proxy = new PostSimpatraServiceProxy();
  let query = `jpatient/v1/patient/${patientId}/allergy-history`;

  const response = await post_proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg: `Successfully added allergy history.`
  });

  if (response) {
    const { data } = response;
    return data;
  }
};

const updatePatientAllergyHistory = async (_, { payload, patientId, allergyHistoryId }) => {
  const put_proxy = new PutSimpatraServiceProxy();
  let query = `jpatient/v1/patient/${patientId}/allergy-history/${allergyHistoryId}/status`;

  let successNotificationMsg = "";
  if (payload?.showNotif) {
    successNotificationMsg = `Successfully updated patient allergy history status!`;
  }
  const response = await put_proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg,
  });

  if (response) {
    const { data } = response;
    return data;
  }
};

const deactivateAllPatientAllergyHistory = async (_, { payload, patientId }) => {
  const put_proxy = new PutSimpatraServiceProxy();
  let query = `jpatient/v1/patient/${patientId}/nka`;

  const successNotificationMsg = `Successfully updated patient allergy history statuses!`;
  const response = await put_proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg,
  });

  if (response) {
    const { data } = response;
    return data;
  }
};


export default {
  createUpdatePatient,
  createUpdatePatientUserInfo,
  createPatientMergedInfo,
  fetchPatientList,
  updatePatientSendInvoiceStatus,
  fetchPatientListCount,
  fetchPatientAllergyHistory,
  addPatientAllergyHistory,
  updatePatientAllergyHistory,
  deactivateAllPatientAllergyHistory,
};

