import { get, isEmpty } from "lodash";

const setOrgDetails = (state, payload) => {
  state.orgDetails = payload;
};

const setOrgLocations = (state, payload) => {
  state.orgLocations = payload;
};

const setOrgDosingDetails = (state, payload) => {
  state.orgDosingDetails = payload;
};

const setOrgDosingUsers = (state, payload) => {
  state.orgDosingUsers = payload;
};

const updateOrgDosingUsers = (state, payload) => {
  if (payload.type === "add") {
    state.orgDosingUsers.push(payload.data);
  } else {
    const dosingIndex = state.orgDosingUsers.findIndex((dosing) => dosing.user.id === payload.userId);
    state.orgDosingUsers.splice(dosingIndex, 1);
  }
};

const setOrgUsers = (state, payload) => {
  const data = get(payload, "content", []);
  state.orgUsers.paginationDetails.currentPage = get(payload, "number", 0);
  state.orgUsers.paginationDetails.totalPages = get(payload, "totalPages", 0);
  state.orgUsers.paginationDetails.pageSize = get(payload, "size", 0);
  state.orgUsers.paginationDetails.totalData = get(payload, "totalElements", 0);
  state.orgUsers.data = data;
};

const updateOrgUser = (state, payload) => {
  if (payload.type === "DELETE") {
    const orgUserIndex = state.orgUsers.data.findIndex((user) => user.id === payload.userId);
    state.orgUsers.data.splice(orgUserIndex, 1);
    state.orgUsers.paginationDetails.totalData = state.orgUsers.paginationDetails.totalData - 1;
  } else if (payload.type === "ADD") {
    state.orgUsers.data.push(payload.data);
    state.orgUsers.paginationDetails.totalData += 1;
  } else {
    const userIndex = state.orgUsers.data.findIndex((item) => {
      return item.id === payload.userId;
    });
    const tempItem = { ...state.orgUsers.data[userIndex] };
    tempItem.firstName = payload.firstName;
    tempItem.lastName = payload.lastName;
    tempItem.email = payload.email;
    tempItem.phone = payload.phone;
    state.orgUsers.data[userIndex] = tempItem;
  }
};

const updateOrgLocation = (state, payload) => {
  const index = state.orgLocations.findIndex((item) => {
    return item.id === payload.locationId;
  });
  state.orgLocations[index].isActive = payload.isActivate;
  state.orgLocations[index].name = payload.name;
};

const updateUserWithNew = (state, payload) => {
  state.orgUsers.data.push(payload);
};

const setOrgList = (state, payload) => {
  const data = get(payload, "content", []);
  state.orgList.paginationDetails.currentPage = get(payload, "number", 0);
  state.orgList.paginationDetails.totalPages = get(payload, "totalPages", 0);
  state.orgList.paginationDetails.pageSize = get(payload, "size", 0);
  state.orgList.paginationDetails.totalData = get(payload, "totalElements", 0);
  state.orgList.data = data;
};

const updateSelectedOrg = (state, payload) => {
  const findOrgIndexOnList = state.orgList.data.findIndex((org) => org.id === payload.selectedOrgId);
  let orgDetails = Object.assign({}, state.orgList.data[findOrgIndexOnList]);
  orgDetails.dosing = get(payload.data, "dosingDetails", {});
  orgDetails.dosingUsers = get(payload.data, "dosingUsers", []);
  orgDetails.orgUsers = get(payload.data, "orgUsers", []);
  orgDetails.availableCards = get(payload.data, "availableCards", []);
  orgDetails.subscription = get(payload.data, "subscription", {});
  orgDetails.logoimgUrl = get(payload.data, "logoimgUrl", []);
  orgDetails.favimgUrl = get(payload.data, "favimgUrl", {});
  state.orgList.data[findOrgIndexOnList] = orgDetails;
};

const setDeaLicenses = (state, payload) => {
  state.deaLicenses = payload;
};

const setMedicalLicenses = (state, payload) => {
  state.medicalLicenses = payload;
};
const setNPI = (state, payload) => {
  state.npi = payload;
};

const setOrgUserLookup = (state, payload) => {
  state.orgUserLookup = payload;
};

export default {
  setOrgDetails,
  setOrgLocations,
  setOrgDosingDetails,
  setOrgDosingUsers,
  setOrgUsers,
  updateOrgUser,
  updateOrgLocation,
  updateUserWithNew,
  setOrgList,
  updateSelectedOrg,
  updateOrgDosingUsers,
  setDeaLicenses,
  setMedicalLicenses,
  setNPI,
  setOrgUserLookup,
};
