import axios from "@/lib/axios";
import notification from "@/utils/notifications";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";

export default {
  state: {
    locationStatus: localStorage.locationStatus,
    id: "",
    name: "",
    phone: "",
    email: "",
    website: "",
    address1: "",
    address2: "",
    city: "",
    region: "",
    postalCode: "",
    country: "",
    addressId: "",
    paymentsAch: [],
    paymentsCard: [],
    availableVendors: [],
    registrations: [],
    receipts: [],
    patients: [],
    orders: [],
    appointments: [],
    patientSearchResults: [],
    currentAppointment: {
      summary: {},
      labs: {},
      dose: {
        info: {},
        calculated: false,
        sex: "",
        recommendedTest: "",
        recommendedEst: "",
      },
    },
    page: 1,
    pageLength: 25,
    isActive: false,
    selectedCurrentLocation: "",
  },

  getters: {
    getPurchaseReceipts: state => {
      return state.receipts;
    },
    getLocationAchAccounts: state => {
      return state.paymentsAch;
    },
    getCurrentLocationName: state => {
      return localStorage.getItem("locationName");
    },
    getCurrentLocationAddress1: state => {
      return state.address1;
    },
    getCurrentLocationAddress2: state => {
      return state.address2;
    },
    getCurrentLocationCity: state => {
      return state.city;
    },
    getCurrentLocationRegion: state => {
      return state.region;
    },
    getCurrentLocationPostalCode: state => {
      return state.postalCode;
    },
    getCurrentLocationRegistrations: state => {
      return state.registrations;
    },
    getCurrentLocationPatients: state => {
      return state.patients;
    },
    getLocationOrders: state => {
      return state.orders.sort((a, b) => {
        if (a.paidAt > b.paidAt) {
          return -1;
        }
        if (a.paidAt < b.paidAt) {
          return 1;
        }
        return 0;
      });
    },
    getLocationAppointments: state => {
      return state.appointments;
    },
    getAvailableVendors: state => {
      return state.availableVendors;
    },
    getLocationId: state => {
      return state.id;
    },
    getSelectedCurrentLocationId() {
      return localStorage.currentLocation;
    },
    getLocationContactInfo: state => {
      const payload = {
        id: state.id,
        name: state.name,
        address1: state.address1,
        address2: state.address2,
        city: state.city,
        region: state.region,
        postalCode: state.postalCode,
        email: state.email,
        phone: state.phone,
        isActive: state.isActive,
      };
      return payload;
    },
    /** --------------- Patients Pagination ----------------------------- */
    getNumberOfPatientsPages(state, getters) {
      const pages = Math.ceil(getters.getCurrentLocationPatients.length / getters.getPageLength);
      if (pages === 0) {
        return 1;
      }
      return pages;
    },
    getPageLength(state) {
      return state.pageLength;
    },
    getPage(state) {
      return state.page;
    },
    getCurrentSelectedLocation() {
      return localStorage.currentLocation;
    },
  },

  mutations: {
    setLocationPatients(state, payload) {
      state.patients = payload;
    },
    setPatientSearchResults(state, payload) {
      state.patientSearchResults = payload;
    },
    setLocationAppointments(state, payload) {
      state.appointments = payload;
    },
    setCurrentAppointment(state, payload) {
      state.currentAppointment.summary = payload;
    },
    setAvailableVendors(state, payload) {
      state.availableVendors = payload;
    },
    setLocationCardAccounts(state, payload) {
      state.paymentsCard = payload;
    },
    setLocationAchAccounts(state, payload) {
      state.paymentsAch = payload;
    },
    setLocationRegistrations(state, payload) {
      state.registrations = payload;
    },
    setLocationReceipts(state, payload) {
      state.receipts = payload;
    },
    setLocationContext(state, payload) {
      // LOCAL STORAGE SETTER
      localStorage.setItem("locationName", payload.name);
      localStorage.setItem("currentLocation", payload.locationId);
      localStorage.setItem("locationStatus", payload.locationStatus);
      // END SETTER
      state.locationStatus = payload.locationStatus;
      state.id = payload.locationId;
      state.selectedCurrentLocation = payload.locationId;
    },
    setLocationDetails(state, payload) {
      state.id = payload.id;
      state.name = payload.name;
      state.phone = payload.phone;
      state.email = payload.email;
      state.website = payload.website;
      state.address1 = payload.address1;
      state.address2 = payload.address2;
      state.city = payload.city;
      state.region = payload.region;
      state.postalCode = payload.postalCode;
      state.country = payload.country;
      state.addressId = payload.addressId;
      state.registrations = payload.registrations;
      state.isActive = payload.isActive;
    },
    resetLocationDetails(state) {
      state.locationStatus = false;
      state.id = "";
      state.name = "";
      state.phone = "";
      state.email = "";
      state.website = "";
      state.address1 = "";
      state.address2 = "";
      state.city = "";
      state.region = "";
      state.postalCode = "";
      state.country = "";
      state.addressId = "";
      state.paymentsAch = [];
      state.paymentsCard = [];
      state.availableVendors = [];
      state.registrations = [];
      state.receipts = [];
      state.patients = [];
      state.appointments = [];
      state.patientSearchResults = [];
      state.dosing = {
        details: {},
        users: [],
      };
      state.currentPatient = {
        info: {},
        labs: [],
        appointments: [],
      };
      state.currentAppointment = {
        summary: {},
        labs: {},
        dose: {
          info: {},
          calculated: false,
          sex: "",
          recommendedTest: "",
          recommendedEst: "",
        },
      };
      localStorage.removeItem("currentLocation");
    },
    setCurrentAppointmentLabs(state, payload) {
      state.currentAppointment.labs = payload;
    },
    setRecommendedMaleDose(state, payload) {
      state.currentAppointment.dose.info = payload;
      state.currentAppointment.dose.calculated = true;
      state.currentAppointment.dose.sex = "male";
      state.currentAppointment.dose.recommendedTest = payload.calculatedTestosterone;
      state.currentAppointment.dose.recommendedEst = "0";
    },
    setRecommendedFemaleDose(state, payload) {
      state.currentAppointment.dose.info = payload;
      state.currentAppointment.dose.calculated = true;
      state.currentAppointment.dose.sex = "female";
      state.currentAppointment.dose.recommendedTest = payload.calculatedTestosterone;
      state.currentAppointment.dose.recommendedEst = payload.calculatedEstradiol;
    },
    clearAppointmentDose(state) {
      state.currentAppointment.dose.info = {};
      state.currentAppointment.dose.calculated = false;
      state.currentAppointment.dose.sex = "";
      state.currentAppointment.dose.recommendedTest = "";
    },
    setLocationOrders(state, payload) {
      state.orders = payload;
    },
    setCurrentLocation(state, payload) {
      state.currentLocationStatus = payload;
    },
    setPage(state, payload) {
      state.page = payload;
    },
    setPurchaseReceipts(state, payload) {
      state.receipts = payload;
    },
  },

  actions: {
    // Get
    fetchLocationDirect: async ({ commit }, locationId) => {
      const response = await axios.get(`/accounts/v1/location/${locationId}`);
      commit("setLocationOnOrder", response.data);
    },
    fetchLocationDetails: async ({ commit, dispatch }, payload) => {
      const { orgId, locationId, returnResponse } = payload;
      const response = await axios.get(`/accounts/v1/org/${orgId}/location/${locationId}`);
      if (!returnResponse) {
        commit("setLocationDetails", response.data);
      }
      return response.data;
    },
    fetchLocationRegistrations: async ({ commit }, payload) => {
      const { orgId } = payload;
      const { locationId } = payload;

      const response = await axios.get(`/accounts/v1/org/${orgId}/location/${locationId}/registration`);
      commit("setLocationRegistrations", response.data);
      return response.data;
    },
    fetchLocationPatients: async ({ commit }, currentLocation) => {
      const response = await axios.get(`/patient/v1/location/${currentLocation}/patient`);
      commit("setLocationPatients", response.data);
    },
    fetchLocationPurchases: async ({ commit }, locationId) => {
      const response = await axios.get(`/orders/v1/buyer/${locationId}/purchase`);
      commit("setLocationOrders", response.data);
    },
    clearLocationPurchases: async ({ commit }) => {
      commit("setLocationOrders", []);
    },
    fetchAllVendors: async ({ commit }) => {
      const response = await axios.get("/accounts/v1/vendor");
      commit("setAvailableVendors", response.data);
    },
    fetchAllBuyerReceipts: async ({ commit }, buyerId) => {
      const response = await axios.get(`/orders/v1/buyer/${buyerId}/receipt`);
      commit("setLocationReceipts", response.data);
    },
    fetchBuyerReceipt: async ({ commit }, payload) => {
      const { buyerId } = payload;
      const { purchaseId } = payload;

      const response = await axios.get(`/orders/v1/buyer/${buyerId}/receipt/${purchaseId}`);
      commit("setPurchaseReceipts", response.data);
    },
    fetchAllCardAccounts: async ({ commit }, buyerId) => {
      const response = await axios.get(`/payments/v1/card/buyer/${buyerId}/method`);
      commit("setLocationCardAccounts", response.data);
    },
    fetchAllAchAccounts: async ({ getters, commit }, buyerId) => {
      const isFromVendor = window.location.href.indexOf("/vendor/") !== -1;
      const response = await axios.get(`/payments/v1/ach/buyer/${buyerId}/method`);
      // TODO: add distinction for org vs location
      if ((getters.getUserType === "ORG" || getters.getUserType === "orgUser") && !isFromVendor) {
        commit("setLocationAchAccounts", response.data);
      }
      if (getters.getUserType === "VENDOR" || isFromVendor) {
        commit("setVendorAchAccount", response.data);
      }
    },
    // Add
    addNewLocation: async ({ dispatch }, payload) => {
      const proxy = new PostSimpatraServiceProxy();
      const { orgId } = payload;
      const body = {
        name: payload.name,
        phone: payload.phone,
        fax: payload.fax,
        email: payload.email,
        website: payload.website,
        address1: payload.address1,
        address2: payload.address2,
        city: payload.city,
        region: payload.region,
        postalCode: payload.postalCode,
        country: payload.country,
      };
      const query = `/accounts/v1/org/${orgId}/location`;
      const response = await proxy.execute(query, body, {
        successNotificationMsg: "Successfully added new location.",
        displaySuccessMessage: true,
      });
      dispatch("accountsModule/fetchOrgDetails", { orgId }, { root: true });
      return response;
    },
    addDeaLicense: async ({ dispatch }, payload) => {
      const proxy = new PostSimpatraServiceProxy();
      const { orgId } = payload;
      const { locationId } = payload;
      const body = {
        name: payload.name,
        expiration: payload.expiration,
        deaNumber: payload.deaNumber,
        holderName: payload.holderName,
        holderTitle: payload.holderTitle,
        fileLocation: payload.fileLocation,
        orgUserId: payload.orgUserId,
      };
      const query = `/accounts/v1/org/${orgId}/location/${locationId}/dea`;
      const notificationParams = {
        displaySuccessMessage: true,
        successNotificationMsg: "DEA license added successfully!",
        successNotificationHeader: "Successfully saved!"
      };

      try {
        const response = await proxy.execute(query, body, notificationParams);
        if (response) {
          dispatch("fetchLocationDetails", payload);
          return response;
        }
      } catch (err) {
        return null;
      }
    },
    deleteDeaLicense: async (_, payload) => {
      const { locationId } = payload;
      const { orgId } = payload;
      const { licenseId } = payload;
      await axios.delete(`/accounts/v1/org/${orgId}/location/${locationId}/dea/${licenseId}`);
    },
    addMedicalLicense: async ({ dispatch }, payload) => {
      const proxy = new PostSimpatraServiceProxy();
      const { orgId } = payload;
      const { locationId } = payload;
      const body = {
        name: payload.name,
        state: payload.state,
        expiration: payload.expiration,
        holderName: payload.holderName,
        holderTitle: payload.holderTitle,
        fileLocation: payload.fileLocation,
        orgUserId: payload.orgUserId,
        licenseNumber: payload.licenseNumber,
      };
      const query = `/accounts/v1/org/${orgId}/location/${locationId}/medical`;
      const notificationParams = {
        displaySuccessMessage: true,
        successNotificationMsg: "Medical license added successfully!",
        successNotificationHeader: "Successfully saved!"
      };
      try {
        const response = await proxy.execute(query, body, notificationParams);
        if (response) {
          dispatch("fetchLocationDetails", payload);
          return response;
        }
      } catch (error) {
        return null;
      }
    },
    addNPI: async ({ dispatch }, payload) => {
      const proxy = new PostSimpatraServiceProxy();
      const { orgId } = payload;
      const { locationId } = payload;
      const body = {
        fName: payload.fName,
        lName: payload.lName,
        npiNumber: payload.npiNumber,
        userId: payload.userId,
        orgUserId: payload.orgUserId,
      };
      const query = `/accounts/v1/org/${orgId}/location/${locationId}/npi`;
      const notificationParams = {
        displaySuccessMessage: true,
        successNotificationMsg: "NPI added successfully!",
        successNotificationHeader: "Successfully saved!"
      };
      try {
        const response = await proxy.execute(query, body, notificationParams);
        if (response) {
          dispatch("fetchLocationDetails", payload);
          return response;
        }
      } catch (error) {
        notification("warning", "Failed to save due to error!", error.response.data.message);
      }
    },
    addVendorRegistration: async ({ dispatch }, payload) => {
      const { orgId } = payload;
      const { locationId } = payload;
      const body = {
        deaLicenseId: payload.deaLicenseId,
        medicalLicenseId: payload.medicalLicenseId,
        npiId: payload.npiId,
        vendorId: payload.vendorId,
      };
      try {
        const response = await axios.post(`/accounts/v1/org/${orgId}/location/${locationId}/registration`, body);
        dispatch("fetchLocationRegistrations", payload);
        notification("success", "Successfully saved!", "Product successfully applied for registration.");
        return response;
      } catch (error) {
        notification("warning", "Failed to save due to error!", error.response.data.message);
        return null;
      }
    },
    addAchAccount: async ({ dispatch }, payload) => {
      const { buyerId } = payload;
      const body = {
        accountOwner: payload.accountOwner,
        bankName: payload.bankName,
        accountNumber: payload.accountNumber,
        routingNumber: payload.routingNumber,
        accountType: payload.accountType,
        billingAddress: payload.billingAddress,
      };

      await axios.post(`/payments/v1/ach/buyer/${buyerId}/method`, body);

      dispatch("fetchAllAchAccounts", buyerId);
    },
    updateAchPayoutAccount: async ({ dispatch }, payload) => {
      const { buyerId } = payload;
      const { achId } = payload;
      const body = {
        accountOwner: payload.accountOwner,
        bankName: payload.bankName,
        accountNumber: payload.accountNumber,
        routingNumber: payload.routingNumber,
        accountType: payload.accountType,
        billingAddress: payload.billingAddress,
      };

      await axios.put(`/payments/v1/ach/buyer/${buyerId}/ach/${achId}/method`, body);
      dispatch("fetchAllAchAccounts", buyerId);
    },

    addAchPayoutAccount: async ({ dispatch }, payload) => {
      const { buyerId } = payload;
      const body = {
        accountOwner: payload.accountOwner,
        bankName: payload.bankName,
        accountNumber: payload.accountNumber,
        routingNumber: payload.routingNumber,
        accountType: payload.accountType,
        billingAddress: payload.billingAddress,
      };

      await axios.post(`/payments/v1/ach/buyer/${buyerId}/method`, body);
      dispatch("fetchAllAchAccounts", buyerId);
    },

    // Update
    updateLocationContactInfo: async ({ commit }, payload) => {
      const { orgId } = payload;
      const { locationId } = payload;
      const body = {
        name: payload.name,
        phone: payload.phone,
        fax: payload.fax,
        email: payload.email,
        website: payload.website,
        address1: payload.address1,
        address2: payload.address2,
        city: payload.city,
        region: payload.region,
        postalCode: payload.postalCode,
        country: payload.country,
      };
      const response = await axios.put(`accounts/v1/org/${orgId}/location/${locationId}`, body);
      commit("setLocationDetails", response.data);
      return response.data || null;
    },
    deleteNPI: async (_, payload) => {
      const { orgId } = payload;
      const { locationId } = payload;
      const { npiId } = payload;
      await axios.delete(`/accounts/v1/org/${orgId}/location/${locationId}/npi/${npiId}`);
    },
    deleteMedicalLicense: async (_, payload) => {
      const { orgId } = payload;
      const { locationId } = payload;
      const { licenseId } = payload;

      const response = await axios.delete(`/accounts/v1/org/${orgId}/location/${locationId}/medical/${licenseId}`);
      if (response) {
        notification("success", "Successfully saved!", "Medical license deleted successfully!");
        return payload;
      }
    },
    deleteAchAccount: async ({ dispatch }, payload) => {
      const { buyerId } = payload;
      const { accountId } = payload;

      await axios.delete(`/payments/v1/ach/buyer/${buyerId}/method/${accountId}`);
      dispatch("fetchAllAchAccounts", buyerId);
    },
    resetLocationDetails: ({ commit }) => {
      commit("resetLocationDetails");
    },
    toggleLocationContext: ({ commit }, payload) => {
      commit("setLocationContext", payload);
    },

    changeLocation: async ({ dispatch }, { locationId, isActive }) => {
      const orgId = localStorage.getItem("orgId");
      await dispatch("fetchLocationDetails", { orgId, locationId });
      await dispatch("toggleLocationContext", { locationId, locationStatus: isActive });
      dispatch("fetchAllCardAccounts", locationId);
    },

    /** --------------- Patients Pagination ----------------------------- */
    setCurrentLocation: ({ commit }, payload) => {
      commit("setCurrentLocation", payload);
    },
    nextPage({ getters, commit }) {
      commit("setPage", getters.getPage + 1);
    },
    prevPage({ getters, commit }) {
      commit("setPage", getters.getPage - 1);
    },
    commitPage({ commit }, payload) {
      commit("setPage", payload);
    },
  },
};

